// prechody do galerii
	(function($) {
		$.fn.transitionIntoGallery = function() {
			main = this;
			main.click(function(e) {
				$('body').prepend('<div class="blackLayer" style="position:absolute;top:0px;left:0px;z-index:5000;width:'+$(window).width()+'px;height:'+$("html").height()+'px;background-color:#333f48;display:none"></div>');
				e.preventDefault();
				linkLocation = $(this).attr('href');
				referer = window.location.href;
				viewportOffset = $('html').offset().top * - 1;
				if(document.title.indexOf('|') != -1) {
					if(document.location.href.indexOf('/clanek/') == -1) {
						documentTitle = document.title.substring(0,document.title.indexOf('|') - 1);
					}
					else {
						documentTitle = 'článek';
					}
				}
				else {
					documentTitle = 'Celebwiki.cz';
				}
				var date = new Date();
 				date.setTime(date.getTime() + (5 * 60 * 1000));
				$('.blackLayer').fadeIn(750, function() {
					window.location.href = linkLocation;
				});
			});
		};
	})(jQuery);

// custom social window opener
function newWindow(uri,width,height) {
    if(!window.open(uri,uri,'scrollbars=1,toolbar=0,resizable=1,status=0,width='+width+',height='+height)) {
        document.location.href=uri;
    }
}

/* definice všech globálních akcí po načtení stránky
--------------------------------------------------------------------------------*/

$(document).ready(function(){
	$('a.galleryTransition').each(function() {
		$(this).transitionIntoGallery();
	});

	$('.socialButtons a').click(function() {
    newWindow(this.href,720,420);
    return false;
});

});

/*
	* stickyfloat
*/
$.fn.stickyfloat = function(options, lockBottom) {
		var sticky      = this;
		lockBottom = lockBottom || 0;
		if (typeof sticky.offset() != "undefined" && sticky.offset() != null) {
			var objClass 	= sticky.attr("class");
			var topPos 		= sticky.offset().top - parseFloat(sticky.css('marginTop').replace(/auto/, 0));
			var childrenHeightFull = 0;
			sticky.parent().children().each(function(){
				childrenHeightFull = parseFloat(childrenHeightFull) + parseFloat($(this).outerHeight());
			});
			var childrenHeight  = childrenHeightFull - parseFloat(sticky.outerHeight());

			sticky.parent().css('height', sticky.closest(".container").height());
			$(window).scroll(function(evt) {
			var y = $(this).scrollTop();

				if (y > topPos) {
					if (y < (topPos + parseFloat(sticky.closest(".container").outerHeight()) - childrenHeight - sticky.outerHeight()) - 230) {
						sticky.addClass('fixed').removeAttr('style');
					} else {
						sticky.removeClass('fixed').css({
							position: 'absolute',
							bottom: 0 + lockBottom
						});
					}
				} else {
					sticky.removeClass('fixed');
				}
			});
		}
	};

/* definice všech globálních akcí po načtení stránky
--------------------------------------------------------------------------------*/

	// $(document).ready(function(){
	// 	$('.stickyContent').stickyfloat({ duration: 400 }, 210);
	// });